import styled, { css } from 'styled-components';
import { device } from 'configs/badge';
import { lighten } from 'color2k';

export const Wrapper = styled.div`
    margin-top: ${({ isScreenshotMode }) => (isScreenshotMode ? '66px' : '91px')};
    transform: ${({ isScreenshotMode }) => (isScreenshotMode ? 'scale(1.3)' : '')};
    width: ${({ isScreenshotMode }) => (isScreenshotMode ? '760px !important' : '100%;')};
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    @media ${device.mobileS} {
        flex-direction: column;
    }
`;

export const Container = styled.div`
    ${({ gap }) => `
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: ${gap || '28px'};
            text-align: center;
            width: 50%;
            @media (max-width: 1112px) {
                width: 60%;
            }
            @media (max-width: 1024px) {
                width: 70%;
            }
            @media (max-width: 800px) {
                width: 80%;
            }
            @media (max-width: 678px) {
                width: 70%;
            }
            @media (max-width: 551px) {
                width: 90%;
            }
            @media ${device.mobileS} {
                width: 100%;
            }
        `}
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    gap: ${(props) => props.gap || '12px'};
`;

export const Title = styled.p`
    padding: 0 40px;
    line-height: 1.3;
    font-weight: bold;
    color: var(--font-color);
    text-align: center;
    font-size: 44px;
    font-family: var(--title-font);

    @media (max-width: 1112px) {
        margin-top: 50px;
        margin-bottom: 10px;
    }
    @media ${device.tablet} {
        font-size: 50px;
        padding: 0 12px;
    }
    @media (max-width: 414px) {
        font-size: 22px;
    }
`;

export const SecondaryTitle = styled.p`
    padding: 0 40px;
    line-height: 1.3;
    padding-top: 4px;
    color: var(--font-color);
    text-align: center;
    font-size: 32px;
    @media (max-width: 1112px) {
        margin-top: 32px;
        margin-bottom: 10px;
    }
    @media ${device.tablet} {
        font-size: 22px;
        padding: 0 12px;
    }
    @media (max-width: 414px) {
        font-size: 16px;
    }
`;

export const Link = styled.a`
    color: white;
    font-size: 15px;
    margin: 0;
    text-underline-offset: 4px;

    &:hover {
        text-decoration: none;
    }
`;

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 86%;
    position: relative;
    box-sizing: border-box;

    @media ${device.tablet} {
        width: 100%;
        padding: 0 10px;
    }
`;

export const UserBlock = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: rgba(255, 255, 255, 0.2) 2px solid;
`;

export const UserPhoto = styled.div`
    img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
`;

export const UserName = styled.div`
    font-family: var(--font);
    color: var(--font-color);
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: left;
    width: min-content;
`;

export const Checkbox = styled.div`
    display: flex;
    text-align: left;

    input {
        position: absolute;
        opacity: 0;
    }

    label {
        cursor: pointer;
        position: relative;
        font-family: var(--font);
        color: var(--font-color);
        font-size: 16px;
        padding-left: 28px;

        &:before {
            content: '';
            top: 0;
            left: 0;
            border: #fff 1px solid;
            border-radius: 4px;
            width: 16px;
            height: 16px;
            position: absolute;
            background-color: #787777;
            transition: all 0.5s;
        }

        &:after {
            content: '';
            position: absolute;
            left: 6px;
            top: 2px;
            width: 4px;
            height: 8px;
            border: solid #000;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            transition: all 0.5s;

            opacity: 0;
        }
    }

    input:not(:checked) {
        & + label {
            opacity: 0.5;
        }
    }

    input:checked {
        & + label {
            opacity: 1;

            &:before {
                background-color: var(--accent1);
                border: var(--accent1) 1px solid;
            }

            &:after {
                opacity: 1;
            }
        }
    }
`;

export const Radio = styled.div`
    display: flex;
    text-align: left;

    input {
        position: absolute;
        opacity: 0;
    }

    label {
        cursor: pointer;
        position: relative;
        font-family: var(--font);
        color: var(--font-color);
        font-size: 16px;
        padding-left: 28px;

        &:before {
            content: '';
            top: 2px;
            left: 0;
            border: #fff 1px solid;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            position: absolute;
            background-color: #787777;
            transition: all 0.5s;
        }

        &:after {
            content: '';
            position: absolute;
            left: 3px;
            top: 5.2px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            transition: all 0.5s;
            background-color: var(--accent-font-color, black);

            opacity: 0;
        }
    }

    input:not(:checked) {
        & + label {
            opacity: 0.5;
        }
    }

    input:checked {
        & + label {
            opacity: 1;

            &:before {
                background-color: var(--accent1);
                border: var(--accent1) 1px solid;
            }

            &:after {
                opacity: 1;
            }
        }
    }
`;

export const Input = styled.input`
    border: none;
    outline: none;
    font-family: var(--font);
    background-color: transparent;
    color: var(--font-color);
    border-bottom: rgba(255, 255, 255, 1) 2px solid;
    font-size: 16px;
    height: 48px;
    width: 100%;

    &[value=''] {
        border-bottom: rgba(255, 255, 255, 0.5) 2px solid;
    }

    :focus {
        border-bottom: rgba(255, 255, 255, 1) 2px solid;
    }

    ::placeholder {
        font-family: var(--font);
        color: var(--font-color);
        opacity: 0.5;
    }

    @media ${device.mobileS} {
        font-size: 14px;
    }
`;

export const SubmitButton = styled.input`
    appearance: none;
    padding: 0;
    font-family: var(--font);
    color: var(--accent1);
    font-size: 20px;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s;
    align-self: flex-end;

    :hover {
        color: var(--accent-hovered, white);
    }
`;

export const ButtonCss = css`
    ${({ fillColor, textColor, width, disabled, borderRadius, textTransform }) => {
        const fill = fillColor || 'var(--accent1)';
        const text = textColor || 'var(--accent-font-color)';
        const hover = fillColor ? lighten(fillColor, 0.1) : 'var(--accent-hovered)';

        return `
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: ${disabled ? 'not-allowed' : 'pointer'};
            opacity: ${disabled ? '0.7' : '1'};
            min-width: 150px;
            min-height: 44px;
            width: ${width || 'auto'};
            background-color: ${fill};
            color: ${text};
            font-family: var(--font);
            font-weight: 500;
            font-size: 14px;
            // border: 2px solid ${fill};
            border: none;
            border-radius: ${borderRadius || '50px'};
            text-transform: ${textTransform || 'uppercase'};
            box-sizing: border-box;
            transition: all 0.3s;
            text-decoration: none;
            padding: 8px 24px;

            svg {
                margin-right: 8px;
                width: 18px;
                height: 18px;
                fill: ${text};
            }

            ${
                !disabled
                    ? `
                        &:hover {
                            background-color: ${hover};
                        }
                    `
                    : ''
            }
        `;
    }}
`;

export const Button = styled.button`
    ${ButtonCss}
`;

export const Textarea = styled.textarea`
    ${({ invalid }) => `
        border: none;
        outline: none;
        overflow-y: hidden;
        resize: none;
        max-width: 100%;
        min-width: 100%;
        font-family: var(--font);
        background-color: transparent;
        color: var(--font-color);
        border-bottom: rgba(255, 255, 255, 1) 2px solid;
        font-size: 16px;
        height: 48px;
        width: 100%;

        &:placeholder-shown {
            border-bottom-color: rgba(255, 255, 255, 0.5);
        }

        :focus {
            border-bottom-color: rgba(255, 255, 255, 1);
        }

        ::placeholder {
            font-family: var(--font);
            color: var(--font-color);
            opacity: 0.5;
        }

        @media ${device.mobileS} {
            font-size: 14px;
        }

        ${
            invalid
                ? `
                    border-bottom-color: #ff4847;

                    &:placeholder-shown {
                        border-bottom-color: #ff4847;
                    }

                    :focus {
                        border-bottom-color: #ff4847;
                    }
                `
                : ''
        }
    `}
`;

export const ShareDetail = styled.div`
    cursor: pointer;
    width: fit-content;
    color: rgba(29, 160, 241, 1);
    font-family: var(--font);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 1.3;

    &:hover {
        text-decoration: underline;
    }
`;

export const ShareText = styled.p`
    padding: 0 40px;
    color: var(--font-color);
    font-size: 16px;
    line-height: 1.5;

    @media ${device.mobileS} {
        margin-top: 14px;
        margin-bottom: 14px;
        padding: 0;
    }
`;

export const PositionText = styled.p`
    color: var(--accent1);
    margin-bottom: 14px;
`;

export const UrlText = styled.p`
    color: var(--font-color);
    font-size: 14px;
    margin-bottom: 8px;
`;

export const Url = styled.span`
    box-sizing: border-box;
    outline: none;
    border: none;
    font-family: var(--font);
    font-size: 14px;
    font-weight: 500;
    padding: 12px 16px;
    background-color: #151917;
    color: var(--font-color);
    border-radius: 50px;
    width: fit-content;
    max-width: 100%;

    white-space: nowrap;
    overflow: overlay;

    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    user-select: all;
    ::selection {
        background: var(--accent1);
        color: var(--accent-font-color);
    }
    ::-moz-selection {
        background: var(--accent1);
        color: var(--accent-font-color);
    }
`;

export const Error = styled.div`
    font-family: var(--font);
    font-size: 12px;
    font-weight: 400;
    color: #ff4847;
    align-self: flex-start;
    text-align: left;
`;

export const RedirectContainer = styled.div`
    box-sizing: border-box;
    font-family: var(--font);
    font-size: 14px;
    font-weight: 500;
    padding: 20px 16px;
    background-color: #151917;
    color: var(--font-color);
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
`;

export const CancelRedirectButton = styled.button`
    ${ButtonCss};
`;
