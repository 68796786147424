import classNames from 'classnames';

export default function Clock2Icon({ className }: { className?: string }) {
    return (
        <svg
            className={classNames('icon', className)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 6.33325C8.13401 6.33325 5 9.46726 5 13.3333C5 17.1992 8.13401 20.3333 12 20.3333C15.866 20.3333 19 17.1992 19 13.3333C19 9.46726 15.866 6.33325 12 6.33325ZM3 13.3333C3 8.36269 7.02944 4.33325 12 4.33325C16.9706 4.33325 21 8.36269 21 13.3333C21 18.3038 16.9706 22.3333 12 22.3333C7.02944 22.3333 3 18.3038 3 13.3333ZM12 8.7777C12.5523 8.7777 13 9.22541 13 9.7777V12.919L15.3738 15.2928C15.7643 15.6833 15.7643 16.3165 15.3738 16.707C14.9832 17.0976 14.3501 17.0976 13.9596 16.707L11.2929 14.0404C11.1054 13.8528 11 13.5985 11 13.3333V9.7777C11 9.22541 11.4477 8.7777 12 8.7777Z"
            />
            <path d="M8 1.33333C8 0.596954 8.59695 0 9.33333 0H14.6667C15.403 0 16 0.596954 16 1.33333V1.33333C16 2.06971 15.403 2.66667 14.6667 2.66667H9.33333C8.59695 2.66667 8 2.06971 8 1.33333V1.33333Z" />
        </svg>
    );
}
