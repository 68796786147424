const size = {
    mobileS: '480px',
    tablet: '767px',
    laptop: '1024px',
    desktop: '1440px',
};

export const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    desktop: `(max-width: ${size.desktop})`,
};
